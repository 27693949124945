import {
  FC,
  RefObject,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
} from "react";
import styled from "styled-components";
import { LrcLyrics, LyricLine, LyricWord } from "../utils/lrcParser";
import Decimal from "decimal.js";

type LrcVisualizerProps = {
  lrc: LrcLyrics;
  audioRef: RefObject<HTMLAudioElement>;
  wordLevel?: boolean;
};

type LrcComponentProps = {
  altColor?: boolean;
};

const LrcVisualizerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-height: 500px;
  overflow-y: scroll;
  background-color: var(--surface);
  margin: 30px;
  padding: 20px;
  border-radius: 4px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LrcLine = styled.div<LrcComponentProps>`
  &.active {
    color: white;
  }
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  color: rgba(231, 234, 246, 0.46);
  font-family: "Noto Sans JP", Arial;
  font-size: 30px;
  line-height: 1.8;
  letter-spacing: 0.009em;
  -webkit-transition: color 200ms linear;
  -ms-transition: color 200ms linear;
  transition: color 200ms linear;
`;

const LrcWord = styled.span<LrcComponentProps>`
  &.active {
    color: #ff0a53e8;
  }
  margin-right: 6px;
  cursor: pointer;
  -webkit-transition: color 50ms linear;
  -ms-transition: color 50ms linear;
  transition: color 50ms linear;
`;

/**
 * Visualize word-level LRC lyrics
 */

const DecimalZero: Decimal = new Decimal(0);

const LrcVisualizer: FC<LrcVisualizerProps> = ({
  lrc,
  audioRef,
  wordLevel,
}) => {
  const firstScroll = useRef<boolean>(true);
  const scrollRef = useRef<Decimal>(DecimalZero);
  const lineRefs = useRef<HTMLDivElement[]>([]);
  const wordRefs = useRef<HTMLSpanElement[]>([]);
  const highlightRefs = useRef<{
    line?: HTMLDivElement;
    word?: HTMLSpanElement;
  }>({});
  // store start index of words for each line to speed up highlight word lookup
  const numWordsPresumRef = useRef<number[]>([]);

  const highlightNode = useCallback(
    (el: HTMLDivElement | HTMLSpanElement, key = "line"): void => {
      if (!el || highlightRefs[key] === el) return;
      clearHighlight(key);
      el.className += " active";
      highlightRefs[key] = el;
    },
    [],
  );

  useEffect(() => {
    const currAudioRef = audioRef.current;

    const clearHighlight = (key) => {
      const el = highlightRefs.current[key];
      if (el) {
        el.className = el.className.slice(0, -7);
      }
    };

    // const clearAudioTime = (time: number = 0) => {
    //   currAudioRef.currentTime = time;
    // };

    const highlightNode = (
      el: HTMLDivElement | HTMLSpanElement,
      idx: number,
      key = "line",
    ): void => {
      if (!el || highlightRefs[key] === el) return;
      clearHighlight(key);
      el.className += " active";
      highlightRefs[key] = el;
      if (key === "line" || el instanceof HTMLDivElement) {
        const parent = document.getElementById("lyrics-container");
        const gap: Decimal = new Decimal(parent.offsetHeight).div(2);
        const currHeight: Decimal = new Decimal(el.offsetHeight);

        const Index: Decimal = new Decimal(idx);
        if (currHeight.mul(scrollRef.current).greaterThanOrEqualTo(gap)) {
          let scrollHeight: Decimal = new Decimal(el.offsetHeight);
          if (firstScroll.current) {
            scrollHeight = scrollHeight.add(scrollHeight.div(2));
            firstScroll.current = false;
          }
          parent.scrollBy(0, scrollHeight.toNumber());
        }
        scrollRef.current = Index;
      }
    };

    const updateLines = () => {
      const currentTime = audioRef.current.currentTime;
      // MARK: highlight the line
      const lineIdx = searchTimeBefore(lrc.lines, currentTime);
      const line = lineRefs.current[lineIdx];
      if (!line) return;
      highlightNode(line, lineIdx);

      if (!wordLevel) return;
      // MARK: highlight the word
      const wordIdx = searchTimeBefore(lrc.lines[lineIdx].words, currentTime);
      if (wordIdx === -1) return; // the first words is NOT started => shall NOT highlight
      const wordPresum = numWordsPresumRef.current[lineIdx];
      const word = wordRefs.current[wordPresum + wordIdx];
      highlightNode(word, wordIdx, "word");
    };
    // compute word index presum for each line
    if (wordLevel) {
      numWordsPresumRef.current = [];
      const arr = numWordsPresumRef.current;
      arr.push(0); // arr[0] = 0
      for (let i = 1; i < lrc.lines.length; i++) {
        arr.push(arr[i - 1] + lrc.lines[i - 1].numWords);
      }
    }
    // add timeupdate event to highlight
    if (currAudioRef) {
      currAudioRef.addEventListener("timeupdate", updateLines);
    }
    return () => {
      currAudioRef?.removeEventListener("timeupdate", updateLines);
    };
  }, [audioRef, lrc, wordLevel, highlightNode]);

  /** Binary search to find the LAST element with start time <= time */
  const searchTimeBefore = (arr: LyricWord[] | LyricLine[], time: number) => {
    // const searchTimeBefore = (arr: LyricLine[], time: number) => {
    let left = 0,
      right = arr.length - 1;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      if (arr[mid].time === time) {
        return mid;
      } else if (arr[mid].time > time) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }
    return right;
  };


  const clearHighlight = (key) => {
    const el = highlightRefs[key];
    if (el) {
      el.className = el.className.slice(0, -7);
    }
  };
  // NOT_USING: Not using yet.
  // MARK: highlightNodeOnClick()
  // const highlightNodeOnClick = (
  //   el: HTMLDivElement | HTMLSpanElement,
  //   idx: number,
  //   key = "line",
  // ): void => {
  //   let targetElement = el;
  //   if (el instanceof HTMLSpanElement || key === "word") {
  //     targetElement = el.parentElement;
  //   }
  //   const parent = document.getElementById("lyrics-container");
  //   const gap: Decimal = new Decimal(parent.offsetHeight).div(2);
  //
  //   const index = new Decimal(idx);
  //   const scrollHeight: Decimal = new Decimal(el.scrollHeight);
  //   targetElement.scrollTo(
  //     0,
  //     scrollRef.current.mul(index).sub(scrollHeight.mul(4).div(gap)).toNumber(),
  //   );
  //   scrollRef.current = index;
  // };

  const pushToArr = (item: any, ref: MutableRefObject<any[]>, idx: number) => {
    if (idx === 0) {
      ref.current = [];
    }
    if (item && !ref.current.includes(item)) {
      ref.current.push(item);
    }
  };

  // const setAudioTime = (time: number) => {
  //   audioRef.current.currentTime = time;
  // };

  return (
    <LrcVisualizerContainer id={"lyrics-container"}>
      {lrc.lines.map((line, lineIdx) => (
        <LrcLine
          key={`${line.time}_${lineIdx}`}
          ref={(el) => pushToArr(el, lineRefs, lineIdx)}
          id={`lrc-${lineIdx}`}
          // onClick={(e) => {
          //   const wordSpan = e.target as HTMLSpanElement;
          //   if (!wordSpan) return;
          //   setAudioTime(+wordSpan.attributes.getNamedItem("data-time").value);
          //   highlightNodeOnClick(e.target as HTMLDivElement, lineIdx);
          // }}
        >
          {line.words.map((word, wordIdx) => (
            <LrcWord
              data-time={word.time}
              key={`${word.text}_${word.time}_${wordIdx}`}
              ref={(el) => {
                if (wordLevel) {
                  pushToArr(
                    el,
                    wordRefs,
                    numWordsPresumRef.current[lineIdx] + wordIdx,
                  );
                }
              }}
            >
              {word.text}
            </LrcWord>
          ))}
        </LrcLine>
      ))}
    </LrcVisualizerContainer>
  );
};

export default LrcVisualizer;
